import {EntityModel} from '../core/model/entity.model';
import {LocalDate} from "@js-joda/core";

export class ClientConfiguration {
  public defaultSellCondition?: string;
  public defaultPaymentMethod?: string;
  public defaultDays?: number;
  public commercialActivities: CommercialActivity[] = [];
}

export class CommercialActivity {
  public code?: string;
  public description?: string;
}

export class Client extends EntityModel {
  public name?: string;
  public type?: ClientType;
  public status?: ClientStatus;
  public commercialName?: string;
  public locationLevel1?: string;
  public locationLevel2?: string;
  public locationLevel3?: string;
  public locationLevel4?: string;
  public location?: string;
  public identification?: string;
  public foreignIdentification?: string;
  public identificationType?: string;
  public email?: string;
  public phoneCountryCode?: number;
  public phone?: string;
  public faxCountryCode?: number;
  public fax?: string;
  public moreDetails?: string;
  public configuration: ClientConfiguration = new ClientConfiguration();
}

export enum ClientType {
  MAIN= 'MAIN',
  CLIENT= 'CLIENT'
}

export enum ClientStatus {
  ACTIVE= 'ACTIVE',
  INACTIVE= 'INACTIVE'
}

export class Configuration extends EntityModel {
  public invoicingPassword?: string;
  public invoicingUser?: string;
  public invoicingCertificate?: string;
  public invoicingPin?: string;
  public systemEmail?: string;
  public publicInstitution?: boolean;
  public generateValueAddedTax?: boolean;
  public publicInstitutionReportData = new ReportConfiguration();
  public periodStartDate?: LocalDate;
  public periodEndDate?: LocalDate;
}

export class PeriodConfiguration {
  public periodStartDate = LocalDate.now();
  public periodEndDate = LocalDate.now();
}

export class ReportConfiguration {
  public president?: string;
  public presidentIdentification?: string;
  public secretary?: string;
  public secretaryIdentification?: string;
  public principal?: string;
  public principalIdentification?: string;
  public circuitCode?: string;
  public directorate?: string;
  public budgetCode?: string;
}

export class ConfigurationAndClient {
  public configuration = new Configuration();
  public client = new Client();
}
